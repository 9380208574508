<template>
	<div class="container">
		<Nav :curIndex="3"></Nav>
		<div class="account_right">
			<div class="my-favorites">
				<div class="title">My Favorites</div>
				<el-table :data="list" style="width: 100%;">
					<el-table-column label="Goods" width="300">
						<template slot-scope="scope">
						    <div class="goods" @click="toDetail(scope.row.id)">
								<img class="img" :src="scope.row.picture" />
								<div class="name">{{scope.row.good_title}}</div>
							</div>
						</template>
					</el-table-column>
					<!-- <el-table-column label="Color">
						<template slot-scope="scope">
						    <el-tag type="danger" size="mini">{{scope.row.color}}</el-tag>
						</template>
					</el-table-column> -->
				    <el-table-column prop="price" label="Price">
                        <template slot-scope="scope">
                            <span>{{$currencySymbol}}{{scope.row.price_trans}}</span>
                        </template>
				    </el-table-column>
					<el-table-column prop="time" label="Time">
					</el-table-column>
				    <el-table-column label="Action" fixed="right">
				        <template slot-scope="scope">
				            <div class="delete-btn" @click="deleteGoods(scope.row.id)">Delete</div>
				        </template>
				    </el-table-column>
				</el-table>
                <div class="pagination-box">
                    <el-pagination
                    class="pagination-pc" 
                    :hide-on-single-page="true"
                    :current-page="currentPage"
                    :pager-count="11"
                    :page-size="pageSize"
                    :total="total" 
                    @current-change="handleCurrentChange" 
                    background 
                    layout="prev, pager, next">
                    </el-pagination>
                    <el-pagination
                    class="pagination-h5" 
                    :hide-on-single-page="true"
                    :current-page="currentPage"
                    :pager-count="5"
                    :page-size="pageSize"
                    :total="total" 
                    @current-change="handleCurrentChange" 
                    background 
                    layout="prev, pager, next">
                    </el-pagination>
                </div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
		components: {
		    Nav: () => import('@/views/account/nav.vue')
		},
        data() {
            return {
                list: [],
                pageSize: 0,
                total: 0,
                currentPage: 1
            }
        },
        created() {
            this.getList()
        },
        methods: {
            getList() {
                this.$api.collect_list({
                    page: this.currentPage
                },true).then(res => {
                    this.list = res.data.data
                    this.pageSize = res.data.per_page
                    this.total = res.data.total
                })
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
                this.getOrderList()
            },
            
            toDetail(id) {
            	this.$router.push({
            	    path: '/detail',
                    query: {
                        id
                    }
            	})
            },
            
            deleteGoods(good_id) {
                this.$api.collect({
                    good_id
                },true).then(res => {
                    this.list.forEach((item, index) => {
                        if(item.id == good_id) {
                            this.list.splice(index,1)
                        }
                    })
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .container {
        	display: flex;
        	width: 100%;
        	box-sizing: border-box;
        	padding: 4rem 0;
        	
        	.account_right {
        		width: 79%;
        		box-sizing: border-box;
        		padding-left: 3rem;
        		
        		.my-favorites {
        			width: 100%;
        			
        			.title {
        				width: 100%;
        				margin-bottom: 1.5rem;
        				font-size: 2rem;
        				color: #333333;
        				word-wrap: break-word;
        			}
        			
        			.goods {
        				display: flex;
        				width: 100%;
        				cursor: pointer;
        				
        				.img {
        					min-width: 10rem;
        					max-width: 10rem;
                            height: 10rem;
        				}
        				
        				.name {
        					width: 100%;
        					box-sizing: border-box;
        					padding-left: .6rem;
        					font-size: 1.4rem;
        					color: #333333;
        					text-align: left;
        					word-break: keep-all;
        					// overflow: hidden;
        					// text-overflow: ellipsis;
        					// display: -webkit-box;
        					// -webkit-line-clamp: 2;
        					// -webkit-box-orient: vertical;
        				}
        			}
        			
        			.delete-btn {
        				display: flex;
        				justify-content: center;
        				align-items: center;
        				min-width: 8rem;
        				height: 3.6rem;
        				box-sizing: border-box;
        				padding: 0 1.5rem;
        				border: 2px solid #333333;
        				border-radius: 2px;
        				font-size: 1.4rem;
        				color: #333333;
        				text-align: center;
        				white-space: nowrap;
        				background-color: #FFFFFF;
        				cursor: pointer;
        				
        				&:hover {
        					color: #FFFFFF;
        					background-color: #333333;
        				}
        			}
                    
                    .pagination-box {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        padding: 3rem 0 0;
                        
                        .pagination-h5 {
                            display: none;
                        }
                    }
        		}
        	}
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .container {
        	width: 100%;
        	box-sizing: border-box;
        	padding: 2rem $h5-box-padding;
        	
        	.account_right {
        		width: 100%;
        		
        		.my-favorites {
        			width: 100%;
        			
        			.title {
        				width: 100%;
        				margin-bottom: 1.2rem;
        				font-size: 1.8rem;
        				color: #333333;
        				word-wrap: break-word;
        			}
        			
        			.goods {
        				display: flex;
        				width: 100%;
        				cursor: pointer;
        				
        				.img {
        					min-width: 8rem;
        					max-width: 8rem;
                            height: 8rem;
        				}
        				
        				.name {
        					width: 100%;
        					box-sizing: border-box;
        					padding-left: .6rem;
        					font-size: 1.2rem;
        					color: #333333;
        					text-align: left;
        					word-break: keep-all;
        					// overflow: hidden;
        					// text-overflow: ellipsis;
        					// display: -webkit-box;
        					// -webkit-line-clamp: 2;
        					// -webkit-box-orient: vertical;
        				}
        			}
        			
        			.delete-btn {
        				display: flex;
        				justify-content: center;
        				align-items: center;
        				max-width: 100%;
        				height: 3.2rem;
        				box-sizing: border-box;
        				padding: 0 1rem;
        				margin-left: 1rem;
        				border: 2px solid #333333;
        				border-radius: 2px;
        				font-size: 1.3rem;
        				color: #333333;
        				text-align: center;
        				white-space: nowrap;
        				background-color: #FFFFFF;
        				cursor: pointer;
        				
        				&:hover {
        					color: #FFFFFF;
        					background-color: #333333;
        				}
        			}
                    
                    .pagination-box {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        padding: 2.5rem 0 0;
                        
                        .pagination-pc {
                            display: none;
                        }
                    }
        		}
        	}
        }
    }
</style>